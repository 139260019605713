<script lang="ts" setup>
import { getProducts } from "@shopware-pwa/api-client";
import { ClientApiError, Product } from "@shopware-pwa/types";

defineEmits<{
  (e: "close"): void;
}>();

const { items, clearWishlist } = useWishlist();
const { apiInstance } = useShopwareContext();
const products = ref<Product[]>([]);
const isLoading = ref(false);

const clearWishlistHandler = async () => {
  try {
    isLoading.value = true;
    await clearWishlist();
  } finally {
    isLoading.value = false;
  }
};
const loadProductsByItemIds = async (itemIds: string[]): Promise<void> => {
  isLoading.value = true;

  try {
    const result = await getProducts(
      {
        ids: itemIds || items.value,
      },
      apiInstance,
    );

    if (result) {
      products.value = result.elements;
    }
  } catch (error) {
    console.error(
      "[wishlist][loadProductsByItemIds]",
      (error as ClientApiError).messages,
    );
  }

  isLoading.value = false;
};

watch(
  items,
  (items, oldItems) => {
    if (items.length !== oldItems?.length) {
      products.value = products.value.filter(({ id }) => items.includes(id));
    }
    if (!items.length) {
      return;
    }
    loadProductsByItemIds(items);
  },
  {
    immediate: true,
  },
);

if (items.length) {
  loadProductsByItemIds(items.value);
}
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <ClientOnly>
    <div :class="{
      'min-h-[60vh]': isLoading || products.length > 0,
    }">
      <div v-if="isLoading" class="absolute z-1 top-21 bottom-0 left-0 right-0 flex justify-center items-center bg-maas-background-default/70 backdrop-blur-sm">
        <span class="spinner"></span>
      </div>
      <template v-if="products.length > 0">
        <SharedFeed :products="products" type="stacked" size="small" :show-product-sizes="true" @close="$emit('close')" />
        <div class="flex justify-center">
          <div @click="clearWishlistHandler">
            <SharedFormButton
              layout="secondaryDark"
              class="mt-8"
              data-testid="clear-wishlist-button"
            >
              {{ $t("wishlist.clearWishlist") }}
            </SharedFormButton>
          </div>
        </div>
      </template>
      <div v-else>
        <p>Sie haben noch keine Produkte zu Ihrer Wunschliste hinzugefügt.</p>
      </div>
    </div>
  </ClientOnly>
</template>
